.button-gray {
    background-color: #c4c5d6;
}

.mat-table-icon {
    width: 30px;
    height: 30px;
}

.mad-container {
    display: flex;
    flex-direction: column;
}

.mad-toolbar-container {
    display: flex;
}

.section-margin-top-60 {
    margin-top: 60px !important;
}

.text-mad {
    color: #546bb3 !important;
}

.mad-badge-notify {
    position: absolute !important;
    left: 50% !important;
    margin-left: -2px !important;
    top: -2px !important;
}

/* .dropdown-menu {
    width:100% !important;
} */

.btn.btn-label-brand {
    background-color: #E4E6EF;
    color: #3F4254;
    line-height: 22px !important;
    border-radius: 4px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: text !important;

}

.navi-item.disabled {
    cursor: none;
    /* background-color: rgba(0, 0, 0, 0.26); */
    color: rgba(0, 0, 0, 0.055) !important;
    pointer-events: none;
}

.btn.btn-label-brand:hover {

    background-color: #5d78ff;
    color: white;
    cursor: text !important;
}

.selected-com {
    background-color: #ff8888;
    color: white;
}

.rowCnt {
    border-right: 5px solid lawngreen !important;
}

.button-disabled {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    cursor: not-allowed !important;
    pointer-events: none !important;

}

.mat-form-field-infix {
    width: 100% !important;
}

.width-130 {
    width: 130px;
}

.custom-dialog-container .mat-dialog-container {
    width: unset !important;
    height: unset !important;
}